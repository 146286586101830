.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 50px 16px 50px;
  border-radius: 20px;
  background-color: var(--pinkRed);
  cursor: pointer;
}

.containerPink {
  background-color: var(--pinkRed10);
}

.containerGreen {
  background-color: var(--landingGreen);
}

.label {
  flex: 1;
  color: var(--white);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
}

.labelPink {
  color: var(--black);
}

.iconContainer {
  display: flex;
  margin-right: 8px;
}
