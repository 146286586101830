@import url("./AppFonts.css");

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--zircon);
  overflow-x: hidden;
}
