.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid var(--grey);
  border-radius: 20px;
}

.containerFocused {
  border-color: var(--black);
}

.containerErrored {
  border-color: var(--red);
}

.divider {
  height: 80%;
  width: 1px;
  margin-left: 12px;
  margin-right: 12px;
  background-color: var(--grey);
}

.input {
  flex: 1;
  height: 24px;
  font-family: Mulish;
  font-size: 16px;
  border: 0px;
  outline: none;
}

.icon {
  height: 20px;
  width: 20px;
}
