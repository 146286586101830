.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explain {
  flex: 1;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
  margin-top: 24px;
}

@media (max-width: 1280px) {
  .container {
    align-items: flex-start;
  }
  .explainRating {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }
  .explainRating {
    text-align: left;
  }
}
