@font-face {
  font-family: "Bergen Text";
  src: url("./assets/fonts/BergenText/BergenText-Regular.otf")
    format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Mulish");