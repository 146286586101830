.container {
  position: relative;
}

.background {
  height: 581.81px;
  width: auto;
}

.content {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 40px;
  right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -2%;
}

.subtitle {
  text-align: center;
  margin-top: 20px;
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
}

.downloadButtons {
  margin-top: 40px;
}
