.container {
  display: flex;
  flex-direction: row;
}

.button {
  height: 46px;
  cursor: pointer;
}

.buttonSmall {
  height: 38px;
}

.buttonMarginLeft {
  margin-left: 20px;
}
