.container {
  position: relative;
}

.background {
  height: 434px;
  width: auto;
}

.content {
  position: absolute;
  top: 60px;
  left: 60px;
  width: 40%;
}

.title {
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  line-height: 130%;
  letter-spacing: -2%;
}

.subtitle {
  margin-top: 20px;
  font-family: Mulish;
  font-size: 21px;
  line-height: 150%;
}

.downloadButtons {
  margin-top: 40px;
}
