.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Bergen Text;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--black);
}

.message {
  margin-top: 10px;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Bergen Text;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--black);
}

.messageSuccess {
  color: green;
}

.messageError {
  color: red;
}

.actionButton {
  margin-top: 20px;
  min-width: 200px;
}
