.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -1%;
  margin-top: 24px;
}

.subtitle {
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  line-height: 140%;
  margin-top: 10px;
}

.icon {
  height: 82px;
  width: 82px;
}
