.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 60px;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.companyLogo {
  max-height: 29px;
  width: auto;
}

.companyLogoMarginBottom {
  margin-top: 40px;
}

@media (max-width: 700px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .companyLogo {
    max-height: 21px;
  }
}

@media (max-width: 500px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .companyLogo {
    max-height: 19px;
  }
}
