.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: #2d2d2d;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.verticalLine {
  height: 24px;
  width: 1px;
  background-color: var(--white);
}

.text {
  margin-left: 10px;
  margin-right: 10px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  color: var(--white);
  cursor: pointer;
}

.copyright {
  margin-top: 20px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}

@media (max-width: 802px) {
  .text {
    font-size: 13px;
  }
  .copyright {
    font-size: 13px;
  }
}
