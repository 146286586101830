.starsContainer {
  display: flex;
  flex-direction: row;
  align-items: row;
  justify-content: center;
  margin-top: 24px;
}

.star {
  width: 24px;
  height: 23.08px;
}
