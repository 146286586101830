.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 60px;
  padding-right: 60px;
}

.containerReversed {
  flex-direction: row-reverse;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.contentInfoReversed {
  margin-left: 80px;
}

.contentTitle {
  font-family: Poppins;
  font-size: 67px;
  font-weight: bold;
  line-height: 130%;
  color: var(--black);
}

.contentSubtitle {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Mulish;
  font-size: 21px;
  line-height: 150%;
  color: var(--black);
}

.mobileFrame {
  width: auto;
  height: 450px;
}

@media (max-width: 1280px) {
  .container {
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
  }

  .containerReversed {
    flex-direction: column-reverse;
  }

  .contentInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: unset;
    margin-bottom: 40px;
  }

  .contentInfoReversed {
    margin-left: 0px;
  }

  .contentTitle {
    text-align: center;
  }

  .contentSubtitle {
    text-align: center;
  }

  .mobileFrame {
    width: auto;
    height: 320px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .containerReversed {
    flex-direction: column-reverse;
  }

  .contentTitle {
    text-align: center;
    font-size: 38px;
  }

  .contentSubtitle {
    text-align: center;
    font-size: 16px;
  }

  .mobileFrame {
    width: auto;
    height: 320px;
  }
}

@media (max-width: 500px) {
  .contentTitle {
    text-align: center;
    font-size: 28px;
  }

  .contentSubtitle {
    text-align: center;
    font-size: 13px;
  }

  .mobileFrame {
    width: auto;
    height: 320px;
  }
}
