.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100px;
  background-color: transparent;
}

.logoContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: "center";
}

.ssLogo {
  height: 50px;
  cursor: pointer;
}

.simpleLogo {
  margin-top: 6px;
  margin-left: 16px;
  cursor: pointer;
}

.navigationButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navigationButton {
  margin-right: 16px;
}

.navigationTextButton {
  margin-right: 40px;
}

@media (max-width: 802px) {
  .ssLogo {
    width: 50px;
  }
}

@media (max-width: 500px) {
  .logoContainer {
    justify-content: center;
  }
}
