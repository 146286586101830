.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.contentNoPadding {
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
