.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: var(--white);
  border: 1px solid var(--lighterGreen);
  border-radius: 20px;
  margin-left: 30px;
  margin-right: 30px;
  max-height: 384px;
  width: 370px;
}

.containerRating {
  display: flex;
  max-height: 434px;
}

@media (max-width: 1280px) {
  .container {
    flex-direction: column;
    margin-top: 20px;
    width: unset;
    height: 351px;
  }
  .containerRating {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .containerRating {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    width: unset;
    height: unset;
  }
}
