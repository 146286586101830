.container {
  flex: 1;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 55px;
  padding-right: 55px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 60px;
  border: 1px solid var(--lighterGreen);
  border-radius: 20px;
  background-color: var(--white);
}

.title {
  font-family: Poppins;
  font-size: 38px;
  font-weight: bold;
  line-height: 130%;
  letter-spacing: -2%;
}

.subtitle {
  margin-top: 10px;
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
}

.form {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content {
    padding: 20px;
  }
}
