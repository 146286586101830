.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px;
}

.explainBlocksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 60px;
}

.title {
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -2%;
  line-height: 130%;
}

.subtitle {
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
  margin-top: 20px;
}

@media (max-width: 1280px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .explainBlocksContainer {
    flex-direction: column;
  }
}
