.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-width: 458px;
}

.title {
  flex: 1;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: var(--darkestGreen);
}

.infoContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.userTimeInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.userName {
  font-family: "Bergen Text";
  font-size: 16px;
  color: var(--darkestGreen);
}

.time {
  font-family: "Bergen Text";
  font-size: 16px;
  color: var(--black50);
}

.body {
  margin-top: 24px;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: var(--black80);
}

ol {
  padding-left: 20px;
}

.highlightedText {
  display: inline-block;
  text-decoration: underline;
  color: var(--softRed);
  cursor: pointer;
}
