.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  font-style: bold;
  cursor: pointer;
  text-decoration: underline;
}
