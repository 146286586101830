.container {
  cursor: pointer;
}

.label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: var(--darkestGreen);
}
