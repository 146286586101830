.container {
  position: relative;
}

.background {
  height: 434px;
  width: auto;
}

.content {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 50%;
}

.title {
  font-family: Poppins;
  font-size: 38px;
  font-weight: bold;
  line-height: 130%;
  letter-spacing: -2%;
}

.subtitle {
  margin-top: 20px;
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
}

.downloadButtons {
  margin-top: 40px;
}
