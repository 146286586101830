.form {
  display: flex;
  flex-direction: row;
}

.formColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formColumnRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email {
  font-family: Mulish;
  font-size: 16px;
  line-height: 150%;
  margin-left: 12px;
  cursor: pointer;
}

.icon {
  height: 24px;
  width: 24px;
}

.smallIcon {
  height: 20px;
  width: 20px;
}

.formError {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish;
  font-size: 15px;
  color: var(--red);
}

.submit {
  max-width: 160px;
}

@media (max-width: 1280px) {
  .form {
    flex-direction: column;
  }

  .lastFormColumn {
    margin-top: 40px;
  }
}
