:root {
  --yellow: #ded3bd;
  --darkGrey: #33363b;
  --darkGrey80: #33363bcc;
  --grey: #d9d9d9;
  --grey20: #d9d9d933;
  --white: #ffffff;
  --lighterGreen: #e5f4f2;
  --lightGreen: #e0eced;
  --landingGreen: #009379;
  --green: #a0c5c9;
  --green33: #a0c5c954;
  --darkGreen: #4b6b70;
  --darkerGreen: #2f3842;
  --darkestGreen: #1d2e41;
  --darkBlue: #1d2e41;
  --black: #000000;
  --black50: #00000080;
  --black80: #000000cc;
  --appBlack: #33363b;
  --red: red;
  --softRed: #e15f72;
  --pinkRed: #e84880;
  --pinkRed10: #e848801a;
  --transparent: transparent;
  --zircon: #f8f9ff;
}
